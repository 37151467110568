import React from 'react';
import styled, { css } from '@xstyled/emotion';
import { th } from '@xstyled/system';
import { mixins, Colors } from '@oms/ui-utils';

// Theme key = loading

const dotColor = (props: any) =>
  props.dotColor
    ? css`
        background-color: ${props.dotColor};
      `
    : ``;

const textColor = (props: any) =>
  props.textColor
    ? css`
        color: ${props.textColor};
      `
    : ``;

// cubic-bezier(0.03, 0.615, 0.995, 0.415);
export const Dots = styled.div`
  ${mixins.borderBox}
  display: block;
  cursor: wait;

  span[class*='l-'] {
    height: 0.25rem;
    width: 0.25rem;
    display: inline-block;
    margin: 3 1;
    border-radius: rounded;
    animation-name: loader;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    ${th('loading')}
    ${dotColor}
  }

  span.l-1 {
    animation-delay: 1s;
  }
  span.l-2 {
    animation-delay: 0.8s;
  }
  span.l-3 {
    animation-delay: 0.6s;
  }
  span.l-4 {
    animation-delay: 0.4s;
  }
  span.l-5 {
    animation-delay: 0.2s;
  }
  span.l-6 {
    animation-delay: 0;
  }

  @keyframes loader {
    0% {
      transform: translateX(-1rem);
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      transform: translateX(1rem);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

const Container = styled.div`
  ${mixins.borderBox}
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: md;
  color: text;
  ${textColor}
`;

export interface LoadingDotsProps {
  dotColor?: Colors;
  /** Amount of dots, minimum of 3 max of 6 */
  dotCount?: number;
}

export function LoadingDots({ dotColor, dotCount = 6 }: LoadingDotsProps) {
  if (dotCount < 3 || dotCount > 6) {
    throw new Error(
      'The amount of dots should be no less than 3, no more than 6. You passed in: ' +
        dotCount,
    );
  }
  return (
    <Dots dotColor={dotColor}>
      {Array(dotCount)
        .fill('')
        .map((_, index) => (
          <span
            key={`loading-dot-${index}`}
            className={`l-${index + 1}`}
          ></span>
        ))}
    </Dots>
  );
}

export interface LoadingProps {
  dotColor?: Colors;
  textColor?: Colors;
  children: React.ReactNode;
}

export function Loading({ dotColor, textColor, children }: LoadingProps) {
  return (
    <Container aria-busy="true" aria-live="polite" textColor={textColor}>
      {children}
      <LoadingDots dotColor={dotColor} />
    </Container>
  );
}
