import debug from 'debug';

/**
 * Creates a logger that can be deployed to prod (unlike console.log).
 * Can be enabled by running `localStorage.debug = 'oms:*'`
 * in the browser console
 *
 * @param {string} namespace - The namespace to use for the logger
 * @return {function} The logger function
 */
export default (namespace: string) => debug(`oms:${namespace}`);
